import React from "react";

const Publications = () => {
  return (
    <div style={{margin: "5% 5% 5% 5% "}}>
      <h1>Publications</h1>
      <ol>
        <li>Lee, J.*; Sitaraman N. S.*; Sung, Z.; Arias, T. A.; <b>Murthy, A. A.</b>; Grassellino, A.; Romanenko, A., Stress-induced structural changes in superconducting Nb thin films.  <i>Phys. Rev. Mater.</i>, 7 (6), <b>2023</b>, L063201 <a href="https://journals.aps.org/prmaterials/abstract/10.1103/PhysRevMaterials.7.L063201">[HTML]</a></li>
        <li>Bal, M.*; <b>Murthy, A. A.</b>*; Zhu, S.*; Crisa, F.*;...Romanenko, A., Grassellino, A., Systematic Improvements in Transmon Qubit Coherence Enabled by Niobium Surface Encapsulation. arXiv preprint arXiv:2304.13257, <b>2023</b> <a href="https://arxiv.org/abs/2304.13257">[HTML]</a></li>
        <li>Li, S.; Ouyang, D.; Zhang, N.; Zhang, Y.; <b>Murthy, A. A.</b>; Li, Y.; Liu, S.; Zhai, T., Substrate Engineering for Chemical Vapor Deposition Growth of Large‐Scale Two‐Dimensional Transition Metal Dichalcogenides.  <i>Adv. Mater.</i>, <b>2023</b>, 34, 2211855<a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/adma.202211855">[HTML]</a></li>
        <li>He, X.; Deng, Y.; Ouyang, D.; Zhang, N.; Wang, J.; <b>Murthy, A. A.</b>; Spanopoulos, I.; Islam, S. M.; Tu, Q.; Xing, G.; Li, Y.; Dravid, V. P.; Zhai, T., Recent Development of Halide Perovskite Materials and Devices for Ionizing Radiation Detection.  <i>Chem. Rev.</i>, 23 (4), <b>2023</b>, 1207-1261 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.chemrev.2c00404">[HTML]</a></li>       
        <li><b>Murthy, A. A.</b>; Das, P. M.; Ribet, S. M.;  Kopas, C.; Lee, J.; Reagor, M. J.; Zhou, L.; Kramer, M. J.; Hersam, M. C.; Checchin, M.; Grassellino, A.; dos Reis, R.; Dravid, V. P.; Romanenko, A., Developing a Chemical and Structural Understanding of the Surface Oxide in a Niobium Superconducting Qubit.  <i>ACS Nano</i>, 16 (10), <b>2022</b>, 17257–17262 <a href="https://doi.org/10.1021/acsnano.2c07913">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>; Lee, J.; Kopas, C.; Reagor, M. J.; McFadden, A. P.; Pappas, D. P.;  Checchin, M.; Grassellino, A.; Romanenko, A., TOF-SIMS analysis of decoherence sources in superconducting qubits. <i>App. Phys. Lett.</i> 120 (4), <b>2022</b>, 044002 <a href="https://aip.scitation.org/doi/full/10.1063/5.0079321">[HTML]</a> </li>
        <li>Stanev, T. K.; Liu, P.; Zeng, H.; Lenferink, E. J.; <b>Murthy, A. A.</b>; Speiser, N.; Watanabe, K.; Taniguchi, T.; Dravid, V. P.; Stern, N. P., Direct Patterning of Optoelectronic Nanostructures using Encapsulated Layered Transition Metal Dichalcogenides. ACS App. Mater. Interfaces, 14 (20), <b>2022</b>, 23775–23784 <a href="https://pubs.acs.org/doi/abs/10.1021/acsami.2c03652">[HTML]</a></li>
        <li>Lee, J. Y.; Sung, Z; <b>Murthy, A. A.</b>; Reagor, M; Grassellino, A.; Romanenko, A., Discovery of Nb hydride precipitates in superconducting qubits. arXiv preprint arXiv:2108.10385, <b>2021</b> <a href="https://arxiv.org/abs/2108.10385">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>*; Ribet, S. M.*; Roth, E. W.; dos Reis, R.; Dravid, V. P., Imaging Hard-Soft Interfaces in Low-Dimensional  Nanocomposites with Electron Microscopy. [invited review]. <i>Materials Today</i> 50, <b>2021</b>, 100-115 <a href="https://www.sciencedirect.com/science/article/pii/S1369702121001632">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>; Stanev, T. K.; Ribet, S. M.; Watanabe, K.; Taniguchi, T.; Stern, N. P.; dos Reis, R.; Dravid, V. P., Spatial Mapping of Electrostatic Fields across 2D Heterostructures. <i>Nano Lett.</i> 21 (17), <b>2021</b>, 7131-7137 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.nanolett.1c01636">[HTML]</a></li>
        <li>Amsterdam, S. H.; Stanev, T. K; Wang, L.; Zhou, Q.; Irgen-Gioro, S.; Padgaonkar, S.; <b>Murthy, A. A.</b>; Sangwan, V. K.; Dravid, V.P.; Weiss, E. A.; Darancet, P.; Chan, M. K. Y.; Hersam, M. C.; Stern, N. P.; Marks, T. J., Mechanistic Investigation of Molybdenum Disulfide Defect Photoluminescence Quenching by Adsorbed Metallophthalocyanines. <i>J. Am. Chem. Soc.</i> 143 (41), <b>2021</b>, 17153-17161 <a href="https://pubs.acs.org/doi/abs/10.1021/jacs.1c07795">[HTML]</a></li>
        <li>Dereshgi, S. A.; Larciprete, M. C.; Centini, M.; <b>Murthy, A. A.</b>; Tang, K.; Wu, J.; Dravid, V. P.; Aydin, K., Tuning of Optical Phonons in &alpha;-MoO<sub>3</sub>–VO<sub>2</sub> Multilayers, <i>ACS App. Mater. Interfaces</i>, 13 (41), <b>2021</b>, 48981-48987 <a href="https://pubs.acs.org/doi/abs/10.1021/acsami.1c12320">[HTML]</a></li>
        <li>LaMountain, T.; Nelson, J.; Lenferink, E. J.; Amsterdam, S. H.; <b>Murthy, A. A.</b>; Marks, T. J.; Dravid, V.P.; Hersam, M. C.; Stern, N. P., Valley-selective Optical Stark Effect of Exciton-Polaritons in a Monolayer Semiconductor. <i>Nat. Commun.</i> 12 (1), <b>2021</b>, 1-7 <a href="https://www.nature.com/articles/s41467-021-24764-8">[HTML]</a></li>
        <li>DiStefano, J. G.; <b>Murthy, A. A.</b>; Lescott, C. J.; dos Reis, R.; Li, Y.; Dravid, V.P., Structural defects in transition metal dichalcogenide core-shell architectures. <i>App. Phys. Lett.</i> 118 (22), <b>2021</b>, 223103 <a href="https://aip.scitation.org/doi/abs/10.1063/5.0049121">[HTML]</a></li>
        <li>DiStefano, J. G.; <b>Murthy, A. A.</b>; Hao, S.; dos Reis, R.; Wolverton, C.; Dravid, V.P., Au@MoS<sub>2</sub>@WS<sub>2</sub> Topology of Transition Metal Dichalcogenides: The Case of the Core-Shell Architecture.[invited review] <i>Nanoscale</i> 12 (47), <b>2020</b>, 23897-23919 <a href="https://pubs.rsc.org/en/content/articlelanding/2020/nr/d0nr06660e/unauth">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>; Stanev, T. K.; dos Reis, R.; Hao, S.; Wolverton, C.;  Stern, N. P.; Dravid, V. P., Direct Visualization of Electric Field induced Structural Dynamics in Monolayer Transition Metal Dichalcogenides. <i>ACS Nano</i>, 14 (2), <b>2020</b>, 1569-1576 <a href="https://pubs.acs.org/doi/abs/10.1021/acsnano.9b06581">[HTML]</a></li>
        <li>DiStefano, J. G.; <b>Murthy, A. A.</b>; Lescott, C. J.; dos Reis, R.; Li, Y.; Dravid, V.P., Au@MoS<sub>2</sub>@WS<sub>2</sub> Core–Shell Architectures: Combining Vapor Phase and Solution-Based Approaches. <i>J. Phys. Chem. C</i> 124 (4), <b>2020</b>, 2627-2633 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.jpcc.9b11365">[HTML]</a></li>
        <li>Dereshgi, S. A.; Folland, T. G.; <b>Murthy, A. A.</b>; Song, X.; Tanriover, I.; Dravid, V. P.; Caldwell, J. D.; Aydin, K., Lithography-Free, Planar IR Polarization Filters and Converters via Biaxial Phonons in &alpha;-MoO<sub>3</sub> Flakes Integrated into Fabry-Perot Cavities, <i>Nat. Commun.</i>, 11 (1), <b>2020</b>, 1-9 <a href="https://www.nature.com/articles/s41467-020-19499-x">[HTML]</a></li>
        <li>Wei, C.; Dereshgi, S. A.; Song, X.; <b>Murthy, A. A.</b>; Dravid, V. P.; Aydin, K.; Cao, T., Polarization Reflector/Color Filter at Visible Frequencies via Anisotropic &alpha;-MoO<sub>3</sub>. <i>Adv. Opt. Mater.</i>, <b>2020</b>, 2000088 <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/adom.202000088">[HTML]</a></li>
        <li>Li, Y.; <b>Murthy, A. A.</b>; DiStefano, J. G.; Jung, H. J.; Palacios, E.; Hao, S.; Wolverton, C.; Aydin, K.; Chen, X.; Dravid, V.P., MoS<sub>2</sub>-capped Cux Nanocrystals: A New Route for Broadband Photodetection with Transition Metal Dichalcogenides, <i>Mater. Horiz.</i> 6 (3), <b>2019</b>, 587-594 <a href="https://pubs.rsc.org/en/content/articlehtml/2018/mh/c8mh00809d">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>*; Yasaei, P.*; Xu, Y.; dos Reis, R.; Shekhawat, G. S.; Dravid, V. P., Spatial Mapping of Hot-Spots across Interfaces in Monolayer Transition Metal Dichalcogenides, <i>Adv. Mater.</i>, <b>2019</b>, 31, 1808244 <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/adma.201808244">[HTML]</a></li>
        <li>Li, Y.; Moy, E. C.; <b>Murthy, A. A.</b>; Hao, S.; Cain, J. D.; Hanson, E. D.; DiStefano, J. G.; Chae, W. H.; Li, Q.; Wolverton, C.; Chen, X.; Dravid, V. P., Large-Scale Fabrication of MoS2 Ribbons and Their Light-Induced Electronic/Thermal Properties: Dichotomies in the Structural and Defect Engineering. <i>Adv. Funct. Mater.</i>, 28 (13), <b>2018</b>, 1704863 <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/adfm.201704863">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>; Stanev, T. K.; Cain, J. D.; Hao, S.; Wolverton, C.;  Stern, N. P.; Dravid, V. P., Intrinsic transport in 2D heterostructures mediated through h-BN tunneling contacts. <i>Nano Lett.</i>, 18 (5), <b>2018</b>, 2990-2998 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.nanolett.8b00444">[HTML]</a></li>
        <li><b>Murthy, A. A.</b>*; Li, Y.*; Palacios, E.; Li, Q.; Hao, S.; Wolverton, C.; Aydin, K.; Chen, X.; Dravid, V.P., Optically active 1D MoS<sub>2</sub> nano-belts. <i>ACS App. Mater. Interfaces</i>. 10 (8), <b>2018</b>, 6799-6804 <a href="https://pubs.acs.org/doi/abs/10.1021/acsami.7b16892">[HTML]</a></li>
        <li>Li, Y.; Hao, S.; DiStefano, J. G.; <b>Murthy, A. A.</b>; Hanson, E. D.; Xu, Y.; Wolverton, C.; Chen, X.; Dravid, V.P., Site-Specific Positioning and Patterning of MoS<sub>2</sub> Monolayers - The Role of Au Seeding. <i>ACS Nano</i> 12 (9), <b>2018</b>, 8970-8976 <a href="https://pubs.acs.org/doi/abs/10.1021/acsnano.8b02409">[HTML]</a></li>
        <li>Li, Y.; Majewski, M. B.; Islam, S. M.; <b>Murthy, A. A.</b>; DiStefano, J. G.; Hanson, E. D.; Li, Q.; Kanatzidis, M. G.; Wasielewski, M. R.; Chen, X.; Dravid, V. P., Morphological Engineering of Winged Au@MoS<sub>2</sub> Heterostructures for Electrocatalytic Hydrogen Evolution. <i>Nano Lett.</i> 18 (11), <b>2018</b>, 7104-7110 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.nanolett.8b03109">[HTML]</a></li>
        <li>DiStefano, J. G.; Li, Y.; Jung, H. J.; Hao, S.; <b>Murthy, A. A.</b>; Zhang, X.; Wolverton, C.; Dravid, V.P., Nanoparticle@MoS<sub>2</sub> Core-Shell Architecture: Role of the Core Material. <i>Chem. Mater.</i> 30 (14), <b>2018</b>, 4675-4682 <a href="https://pubs.acs.org/doi/abs/10.1021/acs.chemmater.8b01333">[HTML]</a></li>
        <li>Islam, S. M., Sangwan, V. K., Li, Y., Kang, J., Zhang, X., He, Y., Zhao, J., <b>Murthy, A. A.</b>, Ma, S., Dravid, V. P., Hersam, M. C., Kanatzidis, M. G., Abrupt Thermal Shock of (NH<sub>4</sub>)<sub>2</sub>Mo<sub>3</sub>S<sub>13</sub> Leads to Ultrafast Synthesis of Porous Ensembles of MoS<sub>2</sub> Nanocrystals for High Gain Photodetectors. <i>ACS App. Mater. Interfaces</i> 10 (44), <b>2018</b>, 38193-38200 <a href="https://pubs.acs.org/doi/10.1021/acsami.8b12406">[HTML]</a></li>
        <li>Chae, W. H.; Cain, J. D.; Hanson, E. D.; <b>Murthy, A. A.</b>; Dravid, V. P., Substrate-Induced Strain and Charge Doping in CVD-Grown Monolayer MoS<sub>2</sub>. <i>App. Phys. Lett.</i> 111 (14) <b>2017</b>: 143106 <a href="https://aip.scitation.org/doi/abs/10.1063/1.4998284">[HTML]</a></li>
        <li>Li, Y.; DiStefano, J. G.; <b>Murthy, A. A.</b>; Cain, J. D.; Hanson, E. D.; Hao, S.; Li, Q.; Castro, F. C.; Chen, X.; Dravid, V. P., Plasmon-Induced Interfacial Charge-Transfer Transition in Au@MoS<sub>2</sub> Heterostructures for Superior Plasmonic Photodetectors. <i>ACS Nano</i> 11 (10), <b>2017</b>, 10321-10329 <a href="https://pubs.acs.org/doi/abs/10.1021/acsnano.7b05071">[HTML]</a></li>
        <li>Hanson, E. D.; Lajaunie, L.; Hao, S.; Myers, B. D.; Shi, F.; <b>Murthy, A. A.</b>; Wolverton, C.; Arenal, R.; Dravid, V. P., Systematic Study of Oxygen Vacancy Tunable Transport Properties of Few-Layer MoO<sub>3-x</sub> Enabled by Vapor-Based Synthesis. <i>Adv. Funct. Mater.</i> 27 (17), <b>2017</b> <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/adfm.201605380">[HTML]</a></li>
        <li>Li, Y.; Cain, J. D.; Hanson, E. D.; <b><b>Murthy, A. A.</b></b>; Hao, S.; Shi, F.; Li, Q.; Wolverton, C.; Chen, X.; Dravid, V. P., Au@MoS<sub>2</sub> Core-Shell Heterostructures with Strong Light-Matter Interactions. <i>Nano Lett.</i> 16 (12), <b>2016</b>, 7696-7702. <a href="https://pubs.acs.org/doi/abs/10.1021/acs.nanolett.6b03764">[HTML]</a></li>
        * denotes equal contribution
      </ol>
    </div>
  );
};

export default Publications;
